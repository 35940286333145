import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import richText from "../serializers/richText"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default () => {
  const data = useStaticQuery(graphql`
    {
      sanityTeachingPage {
        description: _rawDescription
        plays {
          _key
          title
          year
          writer
          notes: _rawNotes
        }
        adaptations {
          _key
          title
          description
        }
        gallery {
          _key
          asset {
            url
          }
        }
      }
    }
  `)

  const {
    description,
    plays = [],
    adaptations = [],
    gallery = [],
  } = data.sanityTeachingPage

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    fade: true,
    easing: "ease",
  }

  return (
    <Layout>
      <SEO metaTitle="High School Project" />
      <section className="bio grid-container contained pt0 pt2--md">
        <div className="row">
          <aside className="col c5--lg">
            <div className="rich-text serif--sm serif--md--md">
              <BlockContent blocks={description} serializers={richText} />
            </div>
          </aside>
          {!!gallery.length && (
            <React.Fragment>
              <div className="col c1--lg show--lg" />
              <div className="col c6--lg">
                <div className='row align--center pr2--lg'>
                  <Slider className='col c11 c12--lg' {...sliderSettings}>
                    {gallery.map(item => (
                      <picture key={item._keyy}>
                        <source
                          srcSet={`${item.asset.url}?w=1000&auto=format&q=75`}
                          media="(min-width: 600px)"
                        />
                        <img
                          alt="Peter Coy Playwright"
                          className="db"
                          src={`${item.asset.url}?w=600&auto=format`}
                        />
                      </picture>
                    ))}
                  </Slider>
                </div>
              </div>
            </React.Fragment>
          )}
          {plays.length > 0 && (
            <aside className="col c5--lg pt5 pt10--lg">
              <strong className="serif--md serif--lg--md pb1">
                Plays (Directed)
              </strong>
              {plays.map((play, index) => {
                return (
                  <div key={play._key || play._id} className="row mt1 mt2--md">
                    <span className="col c3 c2--md">
                      <p className="serif--sm serif--md--md">{play.year}</p>
                    </span>
                    <span className="col c9 c6--md">
                      <strong className="serif--sm serif--md--md">
                        {play.title}
                      </strong>
                      {play.writer && (
                        <p className="serif--sm serif--md--md hide--md">
                          <em>by: {play.writer}</em>
                        </p>
                      )}
                      {play.notes && (
                        <div className="serif--sm serif--md--md pt2 rich-text pt1 hide--md">
                          <BlockContent
                            blocks={play.notes}
                            serializers={richText}
                          />
                        </div>
                      )}
                    </span>
                    <span className="col c4--md show--md">
                      {play.writer && (
                        <em className="serif--sm serif--md--md">
                          by: {play.writer}
                        </em>
                      )}
                    </span>
                    <div className="col c2--md show--md"></div>
                    {play.notes && (
                      <div className="col c10--md show--md">
                        <div className="serif--sm serif--md--md pt2 rich-text pt1 ">
                          <BlockContent
                            blocks={play.notes}
                            serializers={richText}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </aside>
          )}
          <div className="col c2--lg show--lg" />
          {adaptations.length > 0 && (
            <aside className="col c5--lg pt5 pt10--lg">
              <strong className="serif--md serif--lg--md pb1">
                Adaptations
              </strong>
              {adaptations.map((play, index) => {
                return (
                  <div key={play._key || play._id} className="row mt1 mt2--md">
                    <span className="col c12 c4--md">
                      <strong className="serif--sm serif--md--md">
                        {play.title}
                      </strong>
                    </span>
                    {play.description && (
                      <span className="col c12 c8--md">
                        <div className="serif--sm serif--md--md pt2 rich-text pt1 pt0--md">
                          {play.description}
                        </div>
                      </span>
                    )}
                  </div>
                )
              })}
            </aside>
          )}
        </div>
      </section>
    </Layout>
  )
}
